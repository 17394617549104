import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface globalsState {
  isLoading?: boolean;
}
const initialState: globalsState = {
  isLoading: false,
};
export const globalsSlice = createSlice({
  name: 'globals',
  initialState,
  reducers: {
    updateIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    clearState: (state) => {
      console.log('clear state');
    },
  },
});

export const { updateIsLoading, clearState } = globalsSlice.actions;

export default globalsSlice.reducer;
