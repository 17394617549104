import { useRef, useState } from 'react';
import './feedback.scss';
import { ReactComponent as ArrowSvg } from '@/assets/svgs/arrow-control.svg';
import { motion } from 'framer-motion';
import { fadeIn } from '@/helpers/motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper';
import 'swiper/css';

export default function Feedback() {
  const swiperRef = useRef<SwiperClass | null>(null);
  const [activeSwiper, setActiveSwiper] = useState<number>(1);
  const feedbackLists = [
    {
      title: 'Sanjay',
      subtitle: 'Owner of  SS Sales',
      description: `“InDeliv’s real-time syncing with Tally has saved us hours of manual data entry, while the SMS notifications keep our customers informed!”`,
      border_color: 'rgba(48, 103, 255, 1)',
    },
    {
      title: 'Neha Pathak',
      subtitle: 'Manager At Somya Sales',
      description: `InDeliv’s real-time syncing with Tally has saved us hours of manual data entry, while the SMS notifications keep our customers informed!`,
      border_color: 'rgba(48, 103, 255, 1)',
    },
    {
      title: 'Geetika',
      subtitle: 'CEO At Vainkateshwar',
      description: `“Didn’t think I’d need InDeliv, but now I can’t imagine without it. Saves so much time on deliveries. OTP feature is really helpful too!”`,
      border_color: 'rgba(48, 103, 255, 1)',
    },
  ];

  const handlePrev = () => {
    if (swiperRef.current && activeSwiper !== 1) {
      swiperRef.current.slidePrev();
      setActiveSwiper((prevState) => prevState - 1);
    }
  };

  const handleNext = () => {
    if (swiperRef.current && activeSwiper !== feedbackLists.length) {
      swiperRef.current.slideNext();
      setActiveSwiper((prevState) => prevState + 1);
    }
  };

  return (
    <>
      <div className="feedback-container">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeIn('up', 0.2)}
          whileInView={'show'}
          viewport={{ once: true, amount: 0.2 }}
          className="title"
        >
          <h1>
            What Our <br className="br" /> Customers Say
          </h1>
        </motion.div>

        {/* desktop view */}
        <div className="feedback-list-container desktop">
          {feedbackLists.map((feedback: any, feedbackIdx: number) => {
            return (
              <motion.div
                className="item"
                key={feedbackIdx}
                style={{ outlineColor: feedback?.border_color }}
                initial="hidden"
                animate="visible"
                variants={fadeIn('up', 0.2)}
                whileInView={'show'}
                viewport={{ once: true, amount: 0.2 }}
              >
                <div className="content">
                  <p className="title">{feedback?.title}</p>
                  <p className="subtitle">{feedback?.subtitle}</p>
                  <p className="description">{feedback?.description}</p>
                </div>
              </motion.div>
            );
          })}
        </div>

        {/* mobile view */}
        <motion.div
          className="feedback-list-container mobile"
          initial="hidden"
          animate="visible"
          variants={fadeIn('up', 0.2)}
          whileInView={'show'}
          viewport={{ once: true, amount: 0.2 }}
        >
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            onSlideChange={() => {
              console.log('slide change');
            }}
            onSwiper={(swiper: any) => {
              swiperRef.current = swiper;
            }}
          >
            {feedbackLists.map((feedback: any, feedbackIdx: number) => {
              return (
                <SwiperSlide key={feedbackIdx}>
                  <div
                    className="item"
                    style={{ outlineColor: feedback?.border_color }}
                  >
                    <div className="content">
                      <p className="title">{feedback?.title}</p>
                      <p className="subtitle">{feedback?.subtitle}</p>
                      <p className="description">{feedback?.description}</p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="swiper-controls">
            <div>
              <p>
                {activeSwiper}/{feedbackLists.length}
              </p>
            </div>
            <div className="control-btn-container">
              <button
                className={`control-btn prevBtn ${
                  activeSwiper === 1 ? 'disable' : ''
                }`}
                onClick={handlePrev}
              >
                <ArrowSvg />
              </button>
              <button
                className={`control-btn ${
                  activeSwiper === feedbackLists.length ? 'disable' : ''
                }`}
                onClick={handleNext}
              >
                <ArrowSvg />
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
}
