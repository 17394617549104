import './faqs.scss';
import { motion } from 'framer-motion';
import { fadeIn } from '@/helpers/motion';
import { useState } from 'react';

export default function Faqs() {
  const [selectedItem, setSelectedItem] = useState<string | null>('01');

  const faqsLists = [
    {
      key: '01',
      label: 'How does Indeliv help my distribution business?',
      description: `Indeliv automates invoicing, payment tracking, delivery management, and more, reducing errors and saving time.`,
    },
    {
      key: '02',
      label: 'Can Indeliv sync with my existing systems?',
      description: `Yes, Indeliv syncs invoices in real-time with Tally.ERP and allows payment records to be transferred, ensuring your data stays accurate.`,
    },
    {
      key: '03',
      label: 'Can I track my deliveries in real-time?',
      description: `Yes, Indeliv provides real-time delivery tracking along with SMS notifications and OTP verification for secure deliveries.`,
    },
    {
      key: '04',
      label:
        'Is it possible to view all payments received for an invoice in one place?',
      description: `Yes, the invoice page provides comprehensive details, including amount, payment type, collector, and date of collection.`,
    },
    {
      key: '05',
      label: 'Does Indeliv display due amounts and delay days?',
      description: `Yes, Indeliv shows due amounts along with the number of delay days based on your search filters.`,
    },
    {
      key: '06',
      label: `Can I create batches today for deliveries scheduled for tomorrow?`,
      description: `Yes, you can create batches in advance and assign them to delivery or collection associates for the next day. You can also add invoices to ongoing batches as required.`,
    },

    {
      key: '07',
      label: `Is there a way to see who performed specific tasks?`,
      description: `Yes, logs provide detailed records of actions, including the user, date, and time they were performed.`,
    },

    {
      key: '08',
      label: 'Can I create custom user roles with different permissions?',
      description: `Yes, Indeliv allows you to create custom user roles with different levels of permissions and access. Once a new role is created, you can customize it by selecting which screens and functions the employee can access. Options to set view, create, edit, and delete permissions are also available for comprehensive control.`,
    },
  ];
  return (
    <>
      <div
        className="faqs-container"
        style={{
          backgroundImage:
            'url(/assets/image/home3-faq-section-bg.png), linear-gradient(180deg, #EFEFFF 0%, #EFEFFF 100%)',
        }}
      >
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeIn('up', 0.2)}
          whileInView={'show'}
          viewport={{ once: true, amount: 0.2 }}
          className="title"
        >
          <h1>FAQs</h1>
        </motion.div>

        <div className="content-container">
          {/* mobile view */}
          <motion.div
            className="content mobile"
            initial="hidden"
            animate="visible"
            variants={fadeIn('up', 0.2)}
            whileInView={'show'}
            viewport={{ once: true, amount: 0.2 }}
          >
            {faqsLists.map((faqs: any, faqsIdx: number) => {
              return (
                <div className="collapse-item" key={faqsIdx}>
                  <div
                    className="collapse-header"
                    onClick={() =>
                      setSelectedItem((prev) =>
                        prev === faqs?.key ? null : faqs?.key
                      )
                    }
                  >
                    <div className="collapse-title">
                      {faqs?.key}. {faqs?.label}
                    </div>
                    <div className="collapse-icon">
                      {selectedItem === faqs?.key ? '-' : '+'}
                    </div>
                  </div>
                  <div
                    className={`collapse-content ${
                      selectedItem === faqs?.key ? 'show' : ''
                    }`}
                  >
                    <p>{faqs?.description}</p>
                  </div>
                </div>
              );
            })}
          </motion.div>

          {/* desktop view (left side)*/}
          <motion.div
            className="content desktop"
            initial="hidden"
            animate="visible"
            variants={fadeIn('right', 0.2)}
            whileInView={'show'}
            viewport={{ once: true, amount: 0.2 }}
          >
            {faqsLists
              .filter((item) => ['01', '02', '03', '04'].includes(item?.key))
              .map((faqs: any, faqsIdx: number) => {
                return (
                  <div className="collapse-item" key={faqsIdx}>
                    <div
                      className="collapse-header"
                      onClick={() =>
                        setSelectedItem((prev) =>
                          prev === faqs?.key ? null : faqs?.key
                        )
                      }
                    >
                      <div className="collapse-title">
                        {faqs?.key}. {faqs?.label}
                      </div>
                      <div className="collapse-icon">
                        {selectedItem === faqs?.key ? '-' : '+'}
                      </div>
                    </div>
                    <div
                      className={`collapse-content ${
                        selectedItem === faqs?.key ? 'show' : ''
                      }`}
                    >
                      <p>{faqs?.description}</p>
                    </div>
                  </div>
                );
              })}
          </motion.div>

          {/* desktop view (right side)*/}
          <motion.div
            className="content desktop"
            initial="hidden"
            animate="visible"
            variants={fadeIn('left', 0.2)}
            whileInView={'show'}
            viewport={{ once: true, amount: 0.2 }}
          >
            {faqsLists
              .filter((item) => ['05', '06', '07', '08'].includes(item?.key))
              .map((faqs: any, faqsIdx: number) => {
                return (
                  <div className="collapse-item" key={faqsIdx}>
                    <div
                      className="collapse-header"
                      onClick={() =>
                        setSelectedItem((prev) =>
                          prev === faqs?.key ? null : faqs?.key
                        )
                      }
                    >
                      <div className="collapse-title">
                        {faqs?.key}. {faqs?.label}
                      </div>
                      <div className="collapse-icon">
                        {selectedItem !== faqs?.key && (
                          <div
                            style={{
                              width: 22,
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                            }}
                          >
                            +
                          </div>
                        )}
                        {selectedItem === faqs?.key && (
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 50 50"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="12"
                              y="24"
                              width="26"
                              height="4"
                              fill="#000000"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                    <div
                      className={`collapse-content ${
                        selectedItem === faqs?.key ? 'show' : ''
                      }`}
                    >
                      <p>{faqs?.description}</p>
                    </div>
                  </div>
                );
              })}
          </motion.div>
        </div>
      </div>
    </>
  );
}
