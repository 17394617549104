import { useRef } from 'react';
import './landing-page.scss';
import Header from '@/components/landingPage/header';
import Breadcrumb from '@/components/landingPage/breadcrumb';
import Feature from '@/components/landingPage/feature';
import Service from '@/components/landingPage/service';
import Feedback from '@/components/landingPage/feedback';
import Contact from '@/components/landingPage/contact';
import Faqs from '@/components/landingPage/faqs';
import Footer from '@/components/landingPage/footer';
import ScrollProgress from '@/components/landingPage/common/ScrollProgress';

export default function LandingPage() {
  const elementsRef = useRef<Record<string, HTMLDivElement | null>>({});

  const handleScrollToElement = (key: string) => {
    const element = elementsRef.current[key];

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <>
      <div className="landingpage-container">
        <Header handleScrollToElement={handleScrollToElement} />
        <div ref={(el) => (elementsRef.current['breadcrumb'] = el)}>
          <Breadcrumb handleScrollToElement={handleScrollToElement} />
        </div>
        <div ref={(el) => (elementsRef.current['feature'] = el)}>
          <Feature />
        </div>
        <div ref={(el) => (elementsRef.current['service'] = el)}>
          <Service />
        </div>
        <Feedback />
        <div ref={(el) => (elementsRef.current['contact'] = el)}>
          <Contact />
        </div>
        <Faqs />
        <Footer handleScrollToElement={handleScrollToElement} />
        <ScrollProgress />
      </div>
    </>
  );
}
