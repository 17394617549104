import './breadcrumb.scss';
import { motion } from 'framer-motion';
import { fadeIn } from '@/helpers/motion';

interface Props {
  handleScrollToElement?: any;
}
export default function Breadcrumb(props: Props) {
  const { handleScrollToElement } = props;

  return (
    <>
      <div
        className="breadcrumb-section"
        style={{
          backgroundImage:
            'url(/assets/image/home3-banner-bg.png), linear-gradient(180deg, #0A0A0A 0%, #0A0A0A 100%)',
        }} 
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-wrapper">
                <div className="banner-content">
                  <motion.div
                    className="title"
                    initial="hidden"
                    animate="visible"
                    variants={fadeIn('down', 0.2)}
                    whileInView={'show'}
                    viewport={{ once: true, amount: 0.2 }}
                  >
                    <h1>
                      Effortless
                      <span style={{ color: '#3067FF', padding: '0px 12px' }}>
                        Delivery and Daybook
                      </span>
                      <br className="br" />
                      Management 
                      {/* for Distributors */}
                    </h1>
                  </motion.div>
                  <motion.div
                    className="subtitle"
                    initial="hidden"
                    animate="visible"
                    variants={fadeIn('up', 0.2)}
                    whileInView={'show'}
                    viewport={{ once: true, amount: 0.2 }}
                  >
                    <p>
                      Track deliveries, manage payments, and streamline
                      operations with InDeliv.
                    </p>
                  </motion.div>
                </div>
                <div className="scroll-down-btn">
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      handleScrollToElement('feature');
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={19}
                      height={29}
                      viewBox="0 0 19 29"
                    >
                      <path d="M9.5 0V28M9.5 28C10 24.3333 12.4 17.1 18 17.5M9.5 28C8.5 24.1667 5.4 16.7 1 17.5" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
