import './feature.scss';
// import Feature1Videos from 'src/assets/videos/inDeliv-video-1.mp4';
// import { ReactComponent as Feature2Videos } from '@/assets/videos/feature-2.svg';
// import { ReactComponent as Feature3Videos } from '@/assets/videos/feature-3.svg';
// import { ReactComponent as Feature4Videos } from '@/assets/videos/feature-4.svg';
// import { ReactComponent as Feature5Videos } from '@/assets/videos/feature-5.svg';
import { ReactComponent as FeatureIcon1Svg } from '@/assets/svgs/feature-icon-1.svg';
import { ReactComponent as FeatureIcon2Svg } from '@/assets/svgs/feature-icon-2.svg';
import { ReactComponent as FeatureIcon3Svg } from '@/assets/svgs/feature-icon-3.svg';
import { ReactComponent as FeatureIcon4Svg } from '@/assets/svgs/feature-icon-4.svg';
import { motion } from 'framer-motion';
import { fadeIn } from '@/helpers/motion';
import { useEffect } from 'react';

export default function Feature() {
  const featureLists = [
    {
      label: 'Real-Time Invoice Syncing from Tally.ERP',
      description:
        'InDeliv syncs all invoices from Tally.ERP instantly, ensuring your system is always up to date.',
      icon: <FeatureIcon1Svg />,
      src: '/assets/videos/inDeliv-video-1.mp4',
    },
    {
      label: 'SMS Delivery Notifications and OTP Verifications',
      description:
        'Keep your customers informed with automated SMS notifications. For added security, our OTP-based delivery ensures the right person receives the order.',
      icon: <FeatureIcon2Svg />,
      src: '/assets/videos/inDeliv-video-2.mp4',
    },
    {
      label: 'Seamless Credit Payment Recording',
      description:
        'For credit payments, your delivery associates can snap a picture of signed bills and upload them, ensuring accurate record-keeping.',
      icon: <FeatureIcon3Svg />,
      src: '/assets/videos/inDeliv-video-3.mp4',
    },
    {
      label: 'Delivery and Collection Summary Report',
      description:
        'At the end of the day, your delivery associates can generate a summary report of all deliveries and collections, making it easy to hand over everything to your manager.',
      icon: <FeatureIcon4Svg />,
      src: '/assets/videos/inDeliv-video-4.mp4',
    },
    {
      label: 'Payment Transfers to Tally.ERP',
      description:
        'InDeliv also allows payments recorded on the platform to be transferred seamlessly to Tally.ERP, ensuring financial records stay in sync.',
      icon: <FeatureIcon1Svg />,
      src: '/assets/videos/inDeliv-video-5.mp4',
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const videos = document.querySelectorAll('video');

      videos.forEach((video: HTMLVideoElement) => {
        const rect = video.getBoundingClientRect();

        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          if (video.paused) {
            video.play();
          }
        } else {
          if (!video.paused) {
            video.pause();
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="feature-container">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeIn('up', 0.2)}
          whileInView={'show'}
          viewport={{ once: true, amount: 0.2 }}
        >
          <div className="title">
            <h1>
              InDeliv
              <span style={{ color: '#3067FF', padding: '0px 12px' }}>
                Features
              </span>
            </h1>
          </div>
          <div className="subtitle">
            <p>Powerful Features to Streamline Your Distribution</p>
          </div>
        </motion.div>
        {featureLists.map((feature: any, featureIdx: number) => {
          return (
            <div
              className="content-list"
              key={featureIdx}
              style={{
                flexDirection: featureIdx % 2 === 0 ? 'row' : 'row-reverse',
              }}
            >
              <motion.div
                // initial="hidden"
                // animate="visible"
                // variants={fadeIn(featureIdx % 2 === 0 ? 'right' : 'left', 0.2)}
                // whileInView={'show'}
                // viewport={{ once: true, amount: 0.2 }}
                className={`item`}
              >
                <div className="icon-container">
                  <div className="icon">{feature?.icon}</div>
                </div>
                <div className="content">
                  <h1>{feature?.label}</h1>
                  <p>{feature?.description}</p>
                </div>
              </motion.div>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={fadeIn(featureIdx % 2 === 0 ? 'left' : 'right', 0.2)}
                whileInView={'show'}
                viewport={{ once: true, amount: 0.2 }}
                className={`image-container`}
              >
                <video muted controls>
                  <source src={feature?.src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </motion.div>
            </div>
          );
        })}
      </div>
    </>
  );
}
