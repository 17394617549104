export const fadeIn = (direction: string, delay: number) => {
  return {
    hidden: {
      x: direction === 'left' ? 40 : direction === 'right' ? -40 : 0,
      y: direction === 'up' ? 40 : direction === 'down' ? -40 : 0,
    },
    show: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        type: 'tween',
        duration: 1.2,
        delay: delay,
        ease: [0.25, 0.25, 0.25, 0.75],
      },
    },
  };
};

export const scaleIn = (direction: string, delay: number) => {
  return {
    hidden: {
      scale: direction === 'up' ? 0.8 : 1,
      opacity: 0.2,
    },
    show: {
      scale: direction === 'up' ? 1 : 0.8,
      opacity: 1,
      transition: {
        type: 'tween',
        duration: 0.3,
        delay: delay,
        ease: [0, 0, 0.58, 1],
      },
    },
  };
};

export const rotateIn = (angle: number, delay: number) => {
  return {
    hidden: {
      rotate: angle,  
      opacity: 1,
    },
    show: {
      rotate: 0,  
      opacity: 1,
      transition: {
        type: 'tween',
        duration: 1,  
        delay: delay,
        ease: [0.25, 0.25, 0.25, 0.75],  
      },
    },
  };
};

export const rotateOnHover = {
  rest: {
    rotate: 0, // Posisi awal
    transition: {
      type: 'tween',
      duration: 0.4,
      ease: [0.25, 0.25, 0.25, 0.75], // Pengaturan ease
    },
  },
  hover: {
    rotate: 360, // Rotasi 360 derajat
    transition: {
      type: 'tween',
      duration: 0.8, // Durasi animasi
      ease: [0.25, 0.25, 0.25, 0.75], // Pengaturan ease
    },
  },
};
