import { Link } from "react-router-dom";
import "./sidebar.scss";
import { NavLink, useLocation } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { LoginUserContext } from "@/App";
import './sidebar.css'



interface SidebarProps {
  setOpenNav?: any;
}


export default function SideBar({ setOpenNav, }: SidebarProps) {
  const context = useContext(LoginUserContext);
  const [userData, setUserData] = useState<any>({});
  const [selectedEmployee, setSelectedEmployee] = useState<boolean>(false);
  const [selectedSettings, setSelectedSettings] = useState<boolean>(false);
  useEffect(() => {
    // console.log("data changed", data);
    // console.log(context?.loginUserData, 'auth')
    if (context?.loginUserData && context?.loginUserData?.role_name) {
      setUserData({...context?.loginUserData}); // Update state when data is available
    }
  }, [context]);
  const hasAccess = useCallback(
      (module: string) => !!userData?.role?.permissions?.find((e: any) => e?.name?.includes(module)),
      [userData?.role?.permissions]
  );
  const location = useLocation();
  const menus = [
    hasAccess("batches") && {
      name: "Batches",
      icon: (active: boolean) => {
        return active
            ? "/assets/Icon/Batches-white.svg"
            : "/assets/Icon/Batches.svg";
      },
      link: "/dashboard/batches",
      key: "/dashboard/batches",
    },
    hasAccess("invoice") && {
      name: "Invoices",
      icon: (active: boolean) => {
        return active
            ? "/assets/Icon/Invoices-white.svg"
            : "/assets/Icon/Invoices.svg";
      },
      link: "/dashboard/invoices",
      key: "/dashboard/invoices",
    },
    hasAccess("payments") && {
      name: "Payments",
      icon: (active: boolean) => {
        return active
            ? "/assets/Icon/Payments-white.svg"
            : "/assets/Icon/Payments.svg";
      },
      link: "/dashboard/payments",
      key: "/dashboard/payments",
    },
    hasAccess("employees") && {
      name: "Employees",
      icon: (active: boolean) => {
        return active
            ? "/assets/Icon/Employees-white.svg"
            : "/assets/Icon/Employees.svg";
      },
      rightIcon: (active: boolean) => {
        return active
            ? "/assets/Icon/arrow-down-white.svg"
            : "/assets/Icon/arrow-down-gray.svg";
      },
      screens: [
        {
          id: "empScreen1",
          name: "Users",
          link: "/dashboard/employees/users",
          hasAccess: hasAccess("employees_users_view"),
        },
        {
          id: "empScreen2",
          name: "Roles",
          link: "/dashboard/employees/roles",
          hasAccess: hasAccess("employees_roles_view"),
        },
      ],
      // link: hasAccess("employees_users_view") ? "/dashboard/employees/users" : "/dashboard/employees/roles",
      key: "/dashboard/employees",
    },
    hasAccess("vehicle") && {
      name: "Vehicles",
      icon: (active: boolean) => {
        return active
            ? "/assets/Icon/Vehicles-white.svg"
            : "/assets/Icon/Vehicles.svg";
      },
      link: "/dashboard/vehicles",
      key: "/dashboard/vehicles",
    },
    (hasAccess("audit_log_view") || hasAccess("sms_log_view")) && {
      name: "Logs",
      icon: (active: boolean) => {
        return active
            ? "/assets/Icon/Audit-white.svg"
            : "/assets/Icon/Audit.svg";
      },
      link: hasAccess("audit_log_view") ? "/dashboard/logs/activity" : "/dashboard/logs/sms",
      key: "/dashboard/logs",
    },
    hasAccess("delivery") && !hasAccess("batches_view") && {
      name: "Deliveries",
      icon: (active: boolean) => {
        return active
            ? "/assets/Icon/Batches-white.svg"
            : "/assets/Icon/Batches.svg";
      },
      link: "/dashboard/delivery/associates",
      key: "/dashboard/delivery/associates",
    },
    hasAccess("collection") && !hasAccess("batches_view") && {
      name: "Collection",
      icon: (active: boolean) => {
        return active
            ? "/assets/Icon/Batches-white.svg"
            : "/assets/Icon/Batches.svg";
      },
      link: "/dashboard/collection/associates",
      key: "/dashboard/collection/associates",
    },
    (hasAccess("sms_settings_view") || hasAccess("apikey_view")) && {
      name: "Settings",
      icon: (active: boolean) => {
        return active
            ? "/assets/Icon/setting-white.svg"
            : "/assets/Icon/setting-gray.svg";
      },
      rightIcon: (active: boolean) => {
        return active
            ? "/assets/Icon/arrow-down-white.svg"
            : "/assets/Icon/arrow-down-gray.svg";
      },
      screens: [
        {
          id: "settingScreen",
          name: "SMS Settings",
          link: "/dashboard/settings/sms",
          hasAccess: hasAccess("sms_settings_view"),
        },
        {
          id: "settingScreen",
          name: "API Key",
          link: "/dashboard/settings/api_key",
          hasAccess: hasAccess("apikey_view"),
        },
      ],
      // link: hasAccess("sms_settings_view") ? "/dashboard/settings/sms" : "/dashboard/settings/api_key",
      key: "/dashboard/settings/",
    },
  ].filter(Boolean);
  const handleMenuClick = (menuName: string) => {
    // const employeeDropdown = document.getElementById('employeeDropdown');
    // const settingsDropdown = document.getElementById('settingsDropdown');
    
    // if (menuName === "Employees") {
    //   if (selectedEmployee) {
    //     employeeDropdown?.classList.remove('slideDownAnimation');
    //     employeeDropdown?.classList.add('slideUpAnimation');
  
    //     setTimeout(() => {
    //       setSelectedEmployee(false);
    //     }, 300);
    //   } else {
    //     setSelectedEmployee(true);
    //     employeeDropdown?.classList.remove('slideUpAnimation');
    //     employeeDropdown?.classList.add('slideDownAnimation');
    //   }
    //   setSelectedSettings(false);
    // } else if (menuName === "Settings") {
    //   if (selectedSettings) {
    //     settingsDropdown?.classList.remove('slideDownAnimation');
    //     settingsDropdown?.classList.add('slideUpAnimation');
  
    //     setTimeout(() => {
    //       setSelectedSettings(false);
    //     }, 300);
    //   } else {
    //     if (selectedEmployee) {
    //       employeeDropdown?.classList.remove('slideDownAnimation');
    //       employeeDropdown?.classList.add('slideUpAnimation');
  
    //       setTimeout(() => {
    //         setSelectedEmployee(false);
    //         setSelectedSettings(true);
    //         settingsDropdown?.classList.remove('slideUpAnimation');
    //         settingsDropdown?.classList.add('slideDownAnimation');
    //       }, 300);
    //     } else {
    //       setSelectedSettings(true);
    //       settingsDropdown?.classList.remove('slideUpAnimation');
    //       settingsDropdown?.classList.add('slideDownAnimation');
    //     }
    //   }
    // } else {
    //   setSelectedEmployee(false);
    //   setSelectedSettings(false);
    //   setOpenNav?.(false);
    // }
    
    if (menuName === "Employees") {
      setSelectedEmployee((prevState) => !prevState);
      setSelectedSettings(false);
    } else if (menuName === "Settings") {
      setSelectedSettings((prevState) => !prevState);
      setSelectedEmployee(false);
    } else {
      setSelectedEmployee(false);
      setSelectedSettings(false);
      setOpenNav?.(false);
    }
  };
  return (
      <div className="d-flex flex-column sidebar  menu-container flex-shrink-0">
        <ul className="nav nav-pills flex-column mb-auto">
          {menus?.map((menu: any, index: number) => {
            let accessibleScreens = menu.screens ? menu.screens.filter((screen: any) => screen.hasAccess) : [];

            if (menu.screens && Array.isArray(menu.screens)) {
              accessibleScreens = menu.screens.filter((screen: any) => screen.hasAccess);
            }

            return (
                <li
                    key={`${index}`}
                    className="nav-item"
                >

                  <div onClick={() => handleMenuClick(menu?.name)} className={`dropdown-container `}>
                    {(menu?.name === "Employees" || menu?.name === "Settings") ? (
                        <>
                          <Link
                              className={`nav-link justify-content-between d-flex sidebar-link ${location.pathname.startsWith(menu?.link) || (menu?.name === "Settings" && location.pathname.startsWith('/dashboard/settings')) || (menu?.name === "Employees" && location.pathname.startsWith('/dashboard/employees')) ? 'active' : ''}`}
                              to={menu?.link}
                          >
                            <div className="d-flex gap-2">
                              <img
                                  src={menu?.icon(location.pathname.startsWith(menu?.link) || (menu?.name === "Settings" && location.pathname.startsWith('/dashboard/settings')) || (menu?.name === "Employees" && location.pathname.startsWith('/dashboard/employees')) ? true : false)}
                                  alt={menu?.name}
                              />
                              <span>{menu?.name}</span>
                            </div>
                            {
                              menu?.rightIcon(location.pathname.startsWith(menu.link)) && (
                                <svg 
                                  style={{
                                    transition: '0.5s',
                                    transform:
                                        (menu.name === "Employees" && selectedEmployee) ||
                                        (menu.name === "Settings" && selectedSettings)
                                            ? 'rotate(180deg)'
                                            : 'rotate(0deg)',
                                  }} 
                                  className="logout-arrow" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M16.5999 7.4585L11.1666 12.8918C10.5249 13.5335 9.4749 13.5335 8.83324 12.8918L3.3999 7.4585" 
                                  stroke-width="1.5" 
                                  stroke-miterlimit="10" 
                                  stroke-linecap="round" 
                                  stroke-linejoin="round" 
                                  stroke={menu.name === "Employees" && location.pathname.includes("/dashboard/employees/")
                                    ? '#ffffff'
                                    : menu.name === "Settings" && location.pathname.includes("/dashboard/settings/")
                                    ? '#ffffff'
                                    : '#667085'}
                                  />
                                </svg>
                              )
                            }
                            {/* <img
                                style={{
                                  transition: '0.5s',
                                  transform:
                                      (menu.name === "Employees" && selectedEmployee) ||
                                      (menu.name === "Settings" && selectedSettings)
                                          ? 'rotate(180deg)'
                                          : 'rotate(0deg)',
                                }}
                                src={menu?.rightIcon(location.pathname.startsWith(menu.link) ? false : menu?.name === "Employees" ? location.pathname.startsWith('/dashboard/employees') : menu?.name === "Settings" ? location.pathname.startsWith('/dashboard/settings') : true)}
                                alt={menu.name}
                            /> */}
                          </Link>

                          {((menu.name === "Employees" && selectedEmployee) || (menu.name === "Settings" && selectedSettings)) && accessibleScreens.length > 0 && (
                              <div id={menu.name === "Employees" ? 'employeeDropdown' : 'settingsDropdown'} className={`d-flex align-items-start justify-content-end svgMenu`}>
                                {accessibleScreens.length > 1 ? (
                                    <img src='/assets/Icon/Union.svg' alt='union' style={{height: "66px"}}/>
                                ) : (
                                    <img src='/assets/Icon/Union-single.svg' alt='single-union'
                                         style={{height: "60px"}}/>
                                )}

                                <div style={{width: '71%'}}>
                                  {accessibleScreens.map((screen: any, screenIDX: number) => (
                                      <NavLink to={screen?.link} style={{textDecoration: "none"}} onClick={(event) => {
                                        event.stopPropagation();
                                      }} key={screen?.id}>
                                        <div style={{
                                          marginTop: accessibleScreens.length > 1 && screenIDX === 1 ? '-7px' : '11px',
                                          fontSize: "14px",
                                          fontWeight: 400,
                                        }} onClick={() => setOpenNav?.(false)}>
                                          <p style={{
                                            backgroundColor: location.pathname.includes(screen?.link) ? "#ECF7FF" : 'transparent',
                                            color: location.pathname.includes(screen?.link) ? "#0080FC" : "#767676",
                                            padding: "5px", borderRadius: "8px", marginBottom: screenIDX === 0 ? 16 : 0
                                          }}>{screen?.name}</p>
                                        </div>
                                      </NavLink>
                                  ))}
                                </div>
                              </div>
                          )}
                        </>
                    ) : (
                        <Link
                            className={`nav-link d-flex sidebar-link ${location.pathname.startsWith(menu?.key) ? 'active' : ''}`}
                            to={menu?.link}>
                          <img
                              src={menu?.icon(location.pathname.startsWith(menu?.key) ? true : false)}
                              alt={menu?.name}
                          />
                          <span>{menu?.name}</span>
                        </Link>
                    )}
                  </div>
                </li>
            );
          })}
        </ul>
      </div>
  );
}
