import { useEffect, useMemo, useRef, useState } from 'react';
import './feedback.scss';
import { ReactComponent as ArrowSvg } from '@/assets/svgs/arrow-control.svg';
import { motion } from 'framer-motion';
import { fadeIn } from '@/helpers/motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';

SwiperCore.use([EffectFade, Navigation, Pagination]);

export default function Feedback() {
  const swiperRef = useRef<SwiperCore>();
  const [isAllowNext, setIsAllowNext] = useState(true);
  const [isAllowPrev, setIsAllowPrev] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const isSmallScreen = useMemo<boolean>(() => {
    return windowWidth <= 767;
  }, [windowWidth]);

  const settings = useMemo(() => {
    return {
      slidesPerView: 1,
      speed: 1500,
      spaceBetween: 30,
      pagination: {
        el: '.franctional-pagi2',
        type: 'fraction',
      },
      navigation: {
        nextEl: '.home3-testimonial-next',
        prevEl: '.home3-testimonial-prev',
      },
      breakpoints: {
        280: {
          slidesPerView: 1,
        },
        386: {
          slidesPerView: 1,
        },
        576: {
          slidesPerView: 1,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        1400: {
          slidesPerView: 3,
        },
      },
    };
  }, []);

  const feedbackLists = [
    {
      title: 'Sanjay',
      subtitle: 'Owner of  SS Sales',
      description: `“InDeliv’s real-time syncing with Tally has saved us hours of manual data entry, while the SMS notifications keep our customers informed!”`,
      border_color: 'rgba(48, 103, 255, 1)',
    },
    {
      title: 'Neha Pathak',
      subtitle: 'Manager At Somya Sales',
      description: `InDeliv’s real-time syncing with Tally has saved us hours of manual data entry, while the SMS notifications keep our customers informed!`,
      border_color: 'rgba(48, 103, 255, 1)',
    },
    {
      title: 'Geetika',
      subtitle: 'CEO At Vainkateshwar',
      description: `“Didn’t think I’d need InDeliv, but now I can’t imagine without it. Saves so much time on deliveries. OTP feature is really helpful too!”`,
      border_color: 'rgba(48, 103, 255, 1)',
    },
    {
      title: 'Kiran Khatri',
      subtitle: 'Manager At Somya Sales',
      description: `“As a collection associate, Indeliv has made my work much easier by enabling fast and error-free payment recording.”`,
      border_color: 'rgba(48, 103, 255, 1)',
    },
    {
      title: 'Prerna Singh',
      subtitle: 'Manager At Somya Sales',
      description: `“Now, expanding my business feels achievable.”`,
      border_color: 'rgba(48, 103, 255, 1)',
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSlideChange = (swiper: SwiperCore) => {
    setIsAllowNext(!swiper.isEnd);
    setIsAllowPrev(!swiper.isBeginning);
  };

  return (
    <>
      <div className="feedback-container">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeIn('up', 0.2)}
          whileInView={'show'}
          viewport={{ once: true, amount: 0.2 }}
          className="title"
        >
          <h1>
            What Our <br className="br" /> Customers Say
          </h1>
        </motion.div>

        <motion.div
          className={`feedback-list-container ${isSmallScreen ? 'mobile' : ''}`}
          initial="hidden"
          animate="visible"
          variants={fadeIn('up', 0.2)}
          whileInView={'show'}
          viewport={{ once: true, amount: 0.2 }}
        >
          <Swiper
            speed={settings.speed}
            spaceBetween={settings.spaceBetween}
            slidesPerView={settings.slidesPerView}
            breakpoints={settings.breakpoints}
            navigation={settings.navigation}
            pagination={settings.pagination}
            onSwiper={(swiper: SwiperCore) => {
              swiperRef.current = swiper;
            }}
            onSlideChange={handleSlideChange}
            style={{ padding: 5 }}
          >
            {feedbackLists.map((feedback: any, feedbackIdx: number) => {
              return (
                <SwiperSlide key={feedbackIdx}>
                  <div
                    className="item"
                    style={{
                      outlineColor: feedback?.border_color,
                    }}
                  >
                    <div className="content">
                      <p className="title">{feedback?.title}</p>
                      <p className="subtitle">{feedback?.subtitle}</p>
                      <p className="description">{feedback?.description}</p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="swiper-controls">
            {!isSmallScreen && (
              <>
                <div></div>
              </>
            )}
            <div className="franctional-pagi2"></div>
            <div className="control-btn-container">
              <button
                className={`control-btn prevBtn home3-testimonial-prev ${
                  !isAllowPrev ? 'disable' : ''
                }`}
              >
                <ArrowSvg />
              </button>
              <button
                className={`control-btn home3-testimonial-next ${
                  !isAllowNext ? 'disable' : ''
                }`}
              >
                <ArrowSvg />
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
}
