import './landing-page.scss';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '@/components/landingPage/header';
import Breadcrumb from '@/components/landingPage/breadcrumb';
import Feature from '@/components/landingPage/feature';
import Service from '@/components/landingPage/service';
import Feedback from '@/components/landingPage/feedback';
import Contact from '@/components/landingPage/contact';
import Faqs from '@/components/landingPage/faqs';
import Footer from '@/components/landingPage/footer';
import ScrollProgress from '@/components/landingPage/common/scrollProgress';
// import ThemeSwitch from '@/components/landingPage/common/theme';

export default function LandingPage() {
  const navigate = useNavigate();
  const elementsRef = useRef<Record<string, HTMLDivElement | null>>({});
  const [isAllowLP, setIsAllowLP] = useState<boolean>(false);

  useEffect(() => {
    const parts = window.location.host.split('.');
    if (parts.length > 2) {
      navigate('/subdomain');
      setIsAllowLP(false);
    } else {
      setIsAllowLP(true);
    }
  }, []);

  const handleScrollToElement = (key: string) => {
    const element = elementsRef.current[key];

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <>
      {isAllowLP && (
        <div className="landingpage-container">
          <Header handleScrollToElement={handleScrollToElement} />
          <div ref={(el) => (elementsRef.current['breadcrumb'] = el)}>
            <Breadcrumb handleScrollToElement={handleScrollToElement} />
          </div>
          <div ref={(el) => (elementsRef.current['feature'] = el)}>
            <Feature />
          </div>
          <div ref={(el) => (elementsRef.current['service'] = el)}>
            <Service />
          </div>
          <Feedback />
          <div ref={(el) => (elementsRef.current['contact'] = el)}>
            <Contact />
          </div>
          <Faqs />
          <Footer handleScrollToElement={handleScrollToElement} />
          <ScrollProgress />
          {/* <ThemeSwitch /> */}
        </div>
      )}
    </>
  );
}
