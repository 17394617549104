import { apiSlice } from '../../apiSlice';
import { updateIsLoading } from './globalsSlice';

export const globalsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        rotateImage: builder.mutation<{image_path: string, image_url: string, message: string},{ image_path: string, angle: string, signal?: AbortSignal }>({
            query: ({image_path='', angle='', signal}) => ({
                url: `rotate_image?image_path=${image_path}&angle=${angle}`,
                method: 'POST',
                body: {},
                signal,
            })
        }),
        sendContactForm: builder.mutation<{message: string},{ full_name: string, company: string, phone: string, email: string, msg: string }>({
            query: ({full_name='', company='', phone='', email='', msg=''}) => ({
                url: `contact?full_name=${full_name}&company=${company}&phone=${phone}&email=${email}&msg=${msg}`,
                method: 'POST',
                body: {},
            })
        })
    })
})

export const { useRotateImageMutation, useSendContactFormMutation } = globalsApiSlice;
