import './service.scss';
import { ReactComponent as ServiceIcon1Svg } from '@/assets/svgs/service-icon-1.svg';
import { ReactComponent as ServiceIcon2Svg } from '@/assets/svgs/service-icon-2.svg';
import { ReactComponent as ServiceIcon3Svg } from '@/assets/svgs/service-icon-3.svg';
import { ReactComponent as ServiceIcon4Svg } from '@/assets/svgs/service-icon-4.svg';
import { motion } from 'framer-motion';
import { fadeIn, rotateOnHover } from '@/helpers/motion';
import { useEffect, useState } from 'react';

export default function Service() {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const serviceLists = [
    {
      label: 'Boost Efficiency',
      description:
        'Automate routine tasks like invoice syncing, delivery notifications, and daybook management.',
      icon: <ServiceIcon1Svg />,
      bg_icon_color: 'rgba(255, 250, 235, 1)',
      border_color: 'rgba(247, 144, 9, 1)',
    },
    {
      label: 'Improve Accuracy',
      description:
        'Record payments, capture signed bill photos, and eliminate manual errors in invoice and payment syncing.',
      icon: <ServiceIcon2Svg />,
      bg_icon_color: 'rgba(236, 253, 243, 1)',
      border_color: 'rgba(32, 217, 130, 1)',
    },
    {
      label: 'Save Time',
      description:
        'From delivery tracking to summarizing collections, InDeliv enhances your team’s productivity.',
      icon: <ServiceIcon3Svg />,
      bg_icon_color: 'rgba(255, 244, 239, 1)',
      border_color: 'rgba(254, 155, 106, 1)',
    },
    {
      label: 'Stay Connected',
      description:
        'Real-time syncing with Tally.ERP ensures your financial records and invoices are always up to date.',
      icon: <ServiceIcon4Svg />,
      bg_icon_color: 'rgba(253, 240, 255, 1)',
      border_color: 'rgba(219, 98, 238, 1)',
    },
  ];

  useEffect(() => {
    if (isHovered) {
      setTimeout(() => {
        setIsHovered(false);
      }, 500);
    }
  }, [isHovered]);

  return (
    <>
      <div
        className="service-container"
        style={{
          backgroundImage:
            'url(/assets/image/home3-testimonial-bg.png), linear-gradient(96deg, #FBD8FF 1.56%, #DEDBFD 50.41%, #C5CCFF 98.25%)',
        }}
      >
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeIn('up', 0.2)}
          whileInView={'show'}
          viewport={{ once: true, amount: 0.2 }}
          className="title"
        >
          <h1>
            Why Choose <br className="br" /> InDeliv?
          </h1>
        </motion.div>

        <div className="service-list-container">
          {serviceLists.map((service: any, serviceIdx: number) => {
            return (
              <motion.div
                className="item"
                key={serviceIdx}
                style={{ outline: `1px solid ${service?.border_color}` }}
                initial="hidden"
                animate="visible"
                variants={fadeIn('up', 0.2)}
                whileInView={'show'}
                viewport={{ once: true, amount: 0.2 }}
                onHoverStart={() => {
                  setIsHovered(true);
                  setHoveredIndex(serviceIdx);
                }}
                onHoverEnd={() => setIsHovered(false)}
              >
                <div className="icon-container">
                  <motion.div
                    className="icon"
                    style={{ backgroundColor: service?.bg_icon_color }}
                    initial="rest"
                    animate={
                      isHovered && hoveredIndex === serviceIdx
                        ? 'hover'
                        : 'rest'
                    }
                    variants={rotateOnHover}
                  >
                    {service?.icon}
                  </motion.div>
                </div>
                <div className="content">
                  <h1>{service?.label}</h1>
                  <p>{service?.description}</p>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>
    </>
  );
}
